/* these styles are applied by using classes directly in the database content */
/* e.g. in the action guides */

.grid-container {
  display: grid;
  grid-template-columns: repeat(12, lfr);
}

.full-width {
  grid-column: span 12;
}

.image-column {
  grid-column: span 4;
  padding: 20px;
}

.content-column {
  grid-column: span 8;
}

.responsive-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

@media screen and (max-width: 680px) {
  .image-column {
      grid-column: span 12;
      padding: 20px;
  }

  .content-column {
      grid-column: span 12;
  }

  .responsive-image {
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-height: 300px;
      border-radius: 4px;
  }
}
